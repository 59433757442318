/** @jsx jsx */
import { jsx, Text, useColorMode } from 'theme-ui';
import React from 'react';
import SinglePost from '../../components/single-post/Index';
import { graphql } from 'gatsby';

var { useEffect } = React;

// display the right color for blog and for notes;
const PostTemplate = (props) => {
  return (
    <div>
      <SinglePost post={props.data} pageContext={props.pageContext} />
    </div>
  );
};

export default PostTemplate;

export const query = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        title
        description
        date
        socialImage {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
      fileAbsolutePath
      body
      internal {
        type
      }
    }
  }
`;
