import {
	bodyColumn,
	gridStyles,
	imageColumn,
} from "../../styles/shared.styles";

export var markdownStyles = (props) => {
	//  get properties from theme-ui theme
	var { text, colors, links, shadows } = props;
	var containerStyles = {
		...gridStyles,
	};
	var childStyles = {
		...bodyColumn,
	};
	var headerStyles = {
		...text.bodyTitle,
		marginBottom: 1,
		marginTop: 4,
		color: "primary",
	};
	var paragraphStyles = {
		...text.articleBody,
		marginTop: 3,
	};
	var orderedListStyles = {
		...text.articleBody,
		padding: 0,
		marginTop: 4,
		marginBottom: 3,
		listStylePosition: "inside",
		listStyleType: "none",
		li: {
			counterIncrement: "incList",
			marginBottom: 1,
			// em: {
			// 	fontFamily: "bodyItalic",
			// },
		},
		"li:before": {
			content: 'counter(incList)". "',
			color: "primary",
		},
		counterReset: "incList",
	};
	var unorderedListStyles = {
		...text.articleBody,
		padding: 0,
		marginTop: 4,
		marginBottom: 3,
		marginLeft: "20px",
		// listStylePosition: "inside",
		listStyleType: "circle",
		li: {
			marginBottom: 1,
		},
	};

	var quoteStyles = {
		marginLeft: 0,
		marginTop: 4,
		marginBottom: 4,
		color: colors.primary,
		// color: colors.grays["100"],
		borderLeft: `1px solid ${colors.grays["40"]}`,
		p: { ...text.blockquote, marginTop: 0 },
	};

	var linkStyles = {
		...links.primary,
		...text.articleBody,
		paddingBottom: "2px",
	};

	// select all images in this class
	var imageStyles = {
		".content-image": {
			...imageColumn,
			marginTop: 4,
			marginBottom: 3,
			// the span is the wrapper for this image
			//  have to give both the span and the image a border radius
			span: {
				borderRadius: 2,
				img: {
					boxShadow: shadows["50"],
					borderRadius: 2,
				},
			},
			h4: {
				fontSize: 1,
				fontWeight: "body",
				marginTop: 4,
				marginBottom: 0,
				color: "grays.60",
			},
			a: {
				fontSize: 1,
				fontWeight: "body",
				fontFamily: "body",
			},
		},
		".gatsby-resp-image-wrapper": {
			marginTop: 3,
			marginBottom: 3,
		},
	};

	return {
		...containerStyles,
		">*": { ...childStyles },
		...imageStyles,
		p: paragraphStyles,
		h2: headerStyles,
		blockquote: quoteStyles,
		a: linkStyles,
		ul: { ...unorderedListStyles },
		ol: { ...orderedListStyles },
	};
};
