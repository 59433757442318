/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import React from "react";
import loadable from "@loadable/component";
import { Link } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Image from "gatsby-image";
import { bodyColumn, gridStyles } from "../../styles/shared.styles";

import { markdownStyles } from "./Index.styles";
import PrevNext from "./prev-next/Index";
import Overview from "./overview/Index";
import SEO from "../seo";

import Fallback from "./postVideo/fallback";

const Video = loadable(
	() => {
		return import(/* webpackPrefetch: true */ "./postVideo/index.js");
	},
	{
		fallback: <Fallback />,
	}
);

const LikeButton = loadable(() => {
	return import("./likeButton/index");
});

const shortCodes = {
	Text,
	Link,
	Image,
	Overview,
	Video,
	jsx,
};

var { useEffect } = React;

const SinglePost = (props) => {
	var { next, previous } = props.pageContext;
	var { mdx } = props.post;
	var { frontmatter, body } = mdx;
	// useEffect(() => {
	// 	console.log(document.querySelector("iframe"));
	// 	// console.log("VIDEOS", videos);
	// 	// .setAttribute("data-cld-autoplay-mode", "on-scroll");
	// }, []);

	return (
		<>
			<SEO
				title={frontmatter.title}
				description={frontmatter.description}
				article={true}
				image={
					frontmatter.socialImage
						? frontmatter.socialImage.childImageSharp.fluid.src.substring(
								1
						  )
						: null
				}
			/>
			<main sx={{ marginTop: 5 }} role="main">
				<div sx={gridStyles}>
					<div sx={bodyColumn}>
						<div sx={{ marginBottom: 4 }}>
							<Text
								variant="display"
								sx={{
									marginBottom: 3,
									marginTop: 2,
								}}
								as="h1"
							>
								{frontmatter.title}
							</Text>
							<Text variant="caption" sx={{ color: "grays.70" }}>
								{frontmatter.description}
							</Text>
						</div>
					</div>
				</div>
				{/* need to pass post title through title prop, so fauna can look it up*/}
				<LikeButton title={frontmatter.title} />
				{/* pass the props from your theme for usage in styling all markdown contents
             works better than teh renderers */}
				<div sx={(props) => markdownStyles(props)}>
					<MDXProvider components={{ ...shortCodes }}>
						<MDXRenderer>{body}</MDXRenderer>
					</MDXProvider>
				</div>
				<div sx={gridStyles}>
					<PrevNext previous={previous} next={next} />
				</div>
			</main>
		</>
	);
};

export default SinglePost;

// {frontmatter.socialImage.childImageSharp.fluid.src}
