import { bodyColumn, listStyles } from "../../../styles/shared.styles";

export const nextPrevStyles = {
	...bodyColumn,
	marginTop: 5,
	marginBottom: 5,
	ul: {
		...listStyles,
		display: "flex",
		flexFlow: "row wrap",
	},
	li: {
		maxWidth: "40%",
		a: (props) => {
			return {
				...props.links.CTA,
				fontSize: 2,
			};
		},
		div: {
			display: "flex",
			flexFlow: "column wrap",
			svg: {
				fontSize: 4,
			},
		},
	},
};

export const nextStyles = {
	marginLeft: "auto",
	textAlign: "right",
	svg: {
		alignSelf: "flex-end",
	},
};
