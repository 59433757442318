/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import React from "react";

const index = () => {
	return (
		<div
			sx={{
				display: "flex",

				flexFlow: "column wrap",
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
				height: "330px",
				backgroundColor: "grays.10",
				marginTop: 4,
				marginBottom: 4,
				boxShadow: 20,
				borderRadius: 2,
				color: "primary",
			}}
		>
			Loading Video ...
		</div>
	);
};

export default index;
