/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { Link } from "gatsby";
import { nextPrevStyles, nextStyles } from "./Index.styles";

import { MdArrowBack, MdArrowForward } from "react-icons/md";

const PrevNext = ({ previous, next }) => {
	return (
		<div sx={nextPrevStyles}>
			<ul>
				<li>
					{previous && !previous.fileAbsolutePath.includes("oss") && (
						<div>
							<MdArrowBack />
							<Link to={previous.fields.slug}>
								{previous.frontmatter.title}
							</Link>
						</div>
					)}
				</li>
				<li sx={nextStyles}>
					{/* this ensures that there is no next link for oss pages */}
					{next && !next.fileAbsolutePath.includes("oss") && (
						<div>
							<MdArrowForward />
							<Link to={next.fields.slug}>
								{next.frontmatter.title}
							</Link>
						</div>
					)}
				</li>
			</ul>
		</div>
	);
};

export default PrevNext;
