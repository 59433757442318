/** @jsx jsx */
import { jsx, Text } from "theme-ui";

const Index = (props) => {
	return (
		<div
			{...props}
			sx={{
				backgroundColor: "primaryTint",
				padding: 3,
				marginBottom: 3,
				borderRadius: 1,
				borderLeft: (props) => `5px solid ${props.colors.primary}`,
				".overviewLink": (props) => {
					return {
						...props.links.primary,
						paddingBottom: "2px",
						fontSize: 2,
					};
				},
			}}
		>
			{/* <Text variant="nav">TL&amp;DR:</Text> */}
			<Text variant="caption">{props.children}</Text>
		</div>
	);
};

export default Index;
